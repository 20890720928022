<template>
  <div class="text-right">

  </div>
</template>

<script type="text/babel">
import $ from "jquery";
import { Toast } from "vant";
import axios from "axios";
import Cookies from 'js-cookie';
import arbg from '../assets/arbg.png';
import zhbg from '../assets/zhbg.png';
import tcbg from '../assets/tcbg.png';
import enbg from '../assets/enbg.png';
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      donghua: '',
      danmus: [],
      datas: [
        {
          value: '135',
          type: 1,
          imgurl: 'https://d.musicapp.migu.cn/prod/playlist-service/playListimg/b0abbda7-1f90-4e17-bc2a-ef600c4c7267.jpg'
        },
      ],
      tasklist: null,
      index: 0,
      disabled: false,
      recordlist: [],
      userInfo: [],
      emptyRecord: false,
      profit: 0,
      token: null,
      uid: null,
      headers: null,
      myiCode: '',
      bgimg: enbg
    };
  },
  created() {
    // this.$completes.loadErudaJs()
    // this.taskdata()
    // this.invitationRecord()
  },
  filters: {
    //取截单元,单位
    playvolume: function (arg) {
      if (arg > 10000) {
        const volume = arg / 10000
        const realVal = parseFloat(volume).toFixed(2);
        return realVal + "W"
      }
      else {
        return arg
      }

    }
  },
  mounted() {
    //页面css初始化
    document.getElementsByTagName("html")[0].style.backgroundColor = "#8260FF";
    document.getElementsByTagName("html")[0].style.padding = "0";
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
    // this.$i18n.locale = 'zh'
    // document.querySelector('html').setAttribute('lang', this.$i18n.locale);

  },
  methods: {
    //返回
    returns() {
      this.$completes.complete("getPreviousPage");
    },
    //分享
    inviteNows() {
      this.$completes.complete("getShare", this.userInfo.lang);
    },

    //原生返回参数
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      const userinfos = Cookies.get('userinfos')
      if (userinfos != undefined) {
        if (userinfos.uid == this.uid) {
          this.myiCode = userinfos.myiCode
        }
      }
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      document.title = this.$t('inviteCash.title')
      this.taskdata()
      this.invitationRecord()

    },

    danmu(item) {
      let that = this
      // this.disabled = true
      if (item.state == 0 && item.time >= item.totalTime) {
        let paramObjs = {
          option: 'post',
          host: this.$serviceIp + 'api/user/task/getUserTaskReward/' + item.id + '?token=' + that.token + '&uid=' + that.uid,
          data: '',
          header: JSON.stringify(that.headers),
        };
        this.$server.curlExec(paramObjs).then((data) => {
          if (data.code == 200) {
            let aa = setInterval(() => {
              that.danmus.push({
                value: data.data[that.index].count,
                imgurl: data.data[that.index].showIcon,
                name: data.data[that.index].name,
              })
              // that.danmus.push({
              //   value: that.datas[that.index].value,
              //   imgurl: that.datas[that.index].imgurl,
              //   name: that.datas[that.index].value,
              // })
              that.index++
              if (that.index >= that.danmus.length) {
                clearInterval(aa)
                that.index = 0
              }

            }, 350);
            that.donghua = 'danmudonghua'
            that.taskdata()
            that.invitationRecord()

          }
          else {
            Toast(data.message)
          }
        });

      }
      else if (item.state == 1) {
        // this.disabled = false

      }
      else {
        this.inviteNows()
      }

    },

    invitationRecord() {
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + 'api/user/invitation/invitationInfo?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(that.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          if (data.data.userProfitResultList == '') {
            that.emptyRecord = false
          }
          else {
            that.emptyRecord = true
          }
          that.recordlist = data.data
          that.myiCode = data.data.myiCode
          let userinfos = {
            uid: that.uid,
            myiCode: data.data.myiCode
          }
          Cookies.set('myiCode', userinfos)
        }
      });
    },

    taskdata() {
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + 'api/user/task/getUserTask/6?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(that.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          that.tasklist = data.data.userTaskResultList
        }
      });
    },

    //copy code
    copyThat(data) {
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast(this.$t('inviteCash.Copy_successfully'))
      oInput.remove()
    },


  },

};

</script>

<style>
/* html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
} */

html[lang="ar"] .text-right {
  direction: rtl;
}

html[lang="ar"] .kdvalue {
  padding-right: 0.37rem;
  padding-left: 0;
}

html[lang="ar"] .recordcoin img {
  margin-left: 0.06rem;
}

html[lang="ar"] .taskdescribe {
  padding-right: 0.16rem;
}

html[lang="ar"] .reward {
  padding-left: 0.36rem;
  padding-right: 0;
  padding-top: 0.085rem;
}

html[lang="ar"] .reward div {
  padding-right: 0.08rem;
  padding-left: 0;
}

html[lang="ar"] .returns {
  -webkit-transform: rotateY(180deg);
}

.box {
  position: relative;
  margin-top: 88px;
}

.heads {
  display: flex;
  align-items: center;
  background: #6c4df9;
  justify-content: center;
  padding: 0 0.27rem;
  height: 44px;
  margin: 0 !important;
  position: fixed;
  z-index: 999;
  top: 0;
  padding-top: 44px;
}

.flexs {
  display: flex;
  align-items: center;
}

.pagetitle {
  font-size: 0.3rem;
  font-family: Regular;
  color: #ffffff;
  line-height: 0.5rem;
  flex: 1;
  text-align: center;
}

.returns {
  width: 0.4rem;
  height: 0.4rem;
}

.help {
  width: 0.47rem;
  height: 0.47rem;
}

.taskbox {
  left: 0;
  right: 0;
  margin: 0.27rem;
}

.title {
  position: absolute;
  bottom: -2.8rem;
  background: #ffd14d;
  margin: 0.27rem;
  left: 0;
  right: 0;
  border-radius: 25px;
  text-align: center;
  font-family: Regular;
}

.codebox {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin: 0.35rem 0;
}

.invitationCode {
  font-size: 0.27rem;
  font-family: Regular;
  color: #6f3e37;
}

.code {
  font-size: 0.4rem;
  font-family: Bolds;
  color: #6f3e37;
  padding: 0 0.2rem 0 0.25rem;
}

.copy {
  font-size: 0.18rem;
  color: #6f3e37;
  background: #fff0c4;
  padding: 0.09rem 0.22rem 0.1rem;
  border-radius: 20px;
  line-height: 1;
}

.kdcoin {
  background: #ffc64d;
  border-radius: 34px;
  margin: 0 0.53rem 1.26rem;
  padding: 0.22rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kdvalue {
  font-size: 0.61rem;
  font-family: Bolds;
  color: #6f3e37;
  padding-left: 0.37rem;
}

.inviteNowbox {
  position: absolute;
  bottom: -0.56rem;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
}

.inviteNow {
  position: relative;
  height: 1.26rem;
}

.inviteNows {
  position: absolute;
  top: 0;
  font-size: 0.5rem;
  font-family: mediums;
  color: #fff0e7;
  padding-top: 0.28rem;
  height: 1rem;
  width: 5rem;
  line-height: 1;
}

.record {
  position: relative;
  line-height: 1;
  text-align: center;
  background: #ffffff19;
  padding-top: 0.01rem;
  border-radius: 25px;
  overflow: hidden;
}

.biaotou1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0.5rem 0 0;
}

.biaotou1 > .titles {
  width: 25%;
  text-align: center;
  font-size: 13px;
  font-family: Semibold;
  font-weight: 600;
  color: #ffd14d;
}

.biaot_content {
  width: 100%;
  padding-bottom: 0.45rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #ffffff;
  font-size: 12px;
  font-family: Regular;
  font-weight: 400;
}

.biaot_content div {
  width: 33%;
}

.recordcoin {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recordcoin img {
  width: 0.3rem;
  height: 0.3rem;
}

.tasks {
  padding: 0.5rem 0;
  font-size: 0.3rem;
  font-family: mediums;
  color: #ffffff;
}

.taskimgbg {
  width: 0.74rem;
  height: 0.74rem;
  background: #ffffff19;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.taskimgbg img {
  width: 0.4rem;
  height: 0.4rem;
}

.taskdescribe {
  font-size: 0.22rem;
  color: #ffffff;
  padding-left: 0.16rem;
  font-family: mediums;
  flex: 1;
}

.reward {
  padding-right: 0.36rem;
  padding-top: 0.085rem;
}

.reward img {
  width: 0.25rem;
  height: 0.25rem;
}

.reward div {
  font-size: 0.18rem;
  font-family: Regular;
  font-weight: 400;
  color: #d8ceff;
  line-height: 1;
  padding-left: 0.08rem;
}

.operation {
  width: 01rem;
  height: 0.33rem;
  font-size: 0.18rem;
  background: #ffd14d;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6f3e37;
  border: none;
  font-family: mediums;
}

.operationtotal {
  background: #fff0c4;
}

.operationtype {
  background: rgb(255 255 255 / 20%);
  color: rgba(255, 255, 255, 0.34);
}

/* animation:myfirst 5s; */

.danmubox {
  width: 50%;
  height: 6rem;
  background: #00000000;
  position: fixed;
  margin: auto;
  bottom: 2rem;
  left: 0;
  right: 0;
}

.danmu {
  width: 2.44rem;
  height: 0.47rem;
  background: #161616;
  opacity: 0;
  border-radius: 28px;
  color: #ffffff;
  font-size: 0.2rem;
  /* padding: 0.11rem 0.25rem; */
  position: fixed;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  justify-content: center;
  margin-bottom: 0.2rem;
}

.danmudonghua {
  animation: myfirst 2s;
  animation-timing-function: linear;
}

@keyframes myfirst {
  0% {
    opacity: 0;
    bottom: 3rem;
  }

  30% {
    opacity: 1;
    bottom: 6rem;
  }

  100% {
    opacity: 0;
    bottom: 9rem;
  }
}
</style>